<template>
  <div>
    <PageHeader />
    <b-overlay>
      <b-card :title="$t('fields.description')">
        <div v-if="isFetchingLottoUserBet" class="text-center text-muted">
          <b-spinner />
        </div>
        <b-row v-else cols="2">
          <b-col>
            <div class="font-size-16 font-weight-bold">ยูสเซอร์เนม</div>
            <a :href="`/players/detail/${lottoUserBet.userId}`" class="text-muted" target="_blank">
              {{ lottoUserBet.user.username }}
            </a>
          </b-col>
          <b-col>
            <div class="font-size-16 font-weight-bold">รอบเกม</div>
            <p class="text-muted">{{ lottoUserBet.lottoGameRound.name }}</p>
          </b-col>
          <b-col>
            <div class="font-size-16 font-weight-bold">ยอดแทง</div>
            <p class="text-muted">{{ lottoUserBet.totalBetAmount | currency }} บาท</p>
          </b-col>
          <b-col>
            <div class="font-size-16 font-weight-bold">ยอดถูกรวม</div>
            <p class="text-muted">{{ lottoUserBet.totalWinAmount | currency }} บาท</p>
          </b-col>
          <b-col>
            <div class="font-size-16 font-weight-bold">สถานะโพย</div>
            <b-badge :variant="displayLottoBetStatusColor(lottoUserBet.lottoBetStatus)">
              {{ displayLottoBetStatusLabel(lottoUserBet.lottoBetStatus) }}
            </b-badge>
          </b-col>
          <b-col>
            <div class="font-size-16 font-weight-bold">เวลาที่แทง</div>
            <p class="text-muted">{{ lottoUserBet.createdAt | datetimeSecond }}</p>
          </b-col>
        </b-row>
      </b-card>

      <b-card :sub-title="`ทั้งหมด ${(isFetchingLottoUserBet ? 0 : lottoUserBet.lottoUserBetDetails.length).toLocaleString()} เลข`" title="รายการแทง">
        <b-row class="mb-3">
          <b-col
            md="4"
            offset-md="8"
          >
            <b-input v-model="search" placeholder="ค้นหาเลข..." type="text" />
          </b-col>
        </b-row>
        <b-table
          :busy="isFetchingLottoUserBet"
          :fields="fields"
          :items="filteredList"
          responsive
          show-empty
        >
          <template #cell(betType)="data">
            {{ getBetTypeLabel(data.value) }}
          </template>

          <template #cell(username)="data">
            {{ data.item.user.username }}
          </template>

          <template #cell(lottoBetStatus)="data">
            <b-badge v-if="lottoUserBet.lottoBetStatus === LottoBetStatusEnum.BET" variant="info">
              {{ $t('utils.status.not_released') }}
            </b-badge>
            <b-badge v-else-if="+data.item.winAmount > 0" variant="success">
              {{ $t('games.win') }}
            </b-badge>
            <b-badge v-else variant="danger">
              {{ $t('games.loss') }}
            </b-badge>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>

          <template #empty="">
            <p class="text-center text-muted">
              {{ $t('messages.nothing_here') }}
            </p>
          </template>
        </b-table>
        <div class="text-right">
          <b-button class="mr-3" variant="white" @click="$router.go(-1)">
            <i class="uil uil-arrow-left"></i>
            {{ $t('buttons.back') }}
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {displayLottoBetStatusColor, displayLottoBetStatusLabel, getBetTypeLabel} from './utils'
import {mapActions, mapGetters, mapState} from 'vuex'
import {LottoBetStatusEnum} from '@src/constants/lotto';

export default {
  page: {
    title: 'รายละเอียดโพย',
  },
  data() {
    return {
      search: '',
      fields: [
        {
          key: 'betType',
          label: 'ประเภท',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'betNumber',
          label: 'เลข',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'betAmount',
          label: 'ราคาแทง',
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'payoutRate',
          label: 'เรทจ่าย',
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'winAmount',
          label: 'ยอดถูก',
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'lottoBetStatus',
          label: 'สถานะ',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoUserBet: (state) => state.lotto.isFetchingLottoUserBet,
    }),
    ...mapGetters(['isMaster', 'isOwner', 'isAgent', 'lottoUserBet']),
    filteredList() {
      const betDetails = this.lottoUserBet.lottoUserBetDetails || []
      return betDetails.filter((betDetail) => betDetail.betNumber.includes(this.search))
    },
    LottoBetStatusEnum() {
      return LottoBetStatusEnum
    },
    lottoUserBetId() {
      return this.$route.params.id
    }
  },
  created() {
    if (this.lottoUserBetId) {
      this.fetchData()
    }
  },
  methods: {
    getBetTypeLabel,
    displayLottoBetStatusLabel,
    displayLottoBetStatusColor,
    ...mapActions(['fetchLottoUserBetByUserBetId']),
    fetchData() {
      this.fetchLottoUserBetByUserBetId(this.lottoUserBetId)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
  },
}
</script>
