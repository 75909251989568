import {
  LottoBetStatusEnum,
  LottoBetTypeEnum,
  LottoGameRoundStatusEnum,
  LottoPrizingTypeEnum
} from '@src/constants/lotto';

const getPrizingTypeLabel = (prizingType) => {
  switch (prizingType) {
    case LottoPrizingTypeEnum.FIRST_PRIZE:
      return 'รางวัลที่ 1'
    case LottoPrizingTypeEnum.FOUR:
      return 'สี่ตัวตรง'
    case LottoPrizingTypeEnum.THREE_FRONT:
      return 'สามตัวหน้า'
    case LottoPrizingTypeEnum.THREE_UNDER:
      return 'สามตัวล่าง'
    case LottoPrizingTypeEnum.THREE_TOP:
      return 'สามตัวบน'
    default:
      return 'สองตัวล่าง'
  }
}

const getNumberLengthByPrizingType = (prizingType) => {
  switch (prizingType) {
    case LottoPrizingTypeEnum.FIRST_PRIZE:
      return 6
    case LottoPrizingTypeEnum.FOUR:
      return 4
    case LottoPrizingTypeEnum.THREE_FRONT:
    case LottoPrizingTypeEnum.THREE_UNDER:
    case LottoPrizingTypeEnum.THREE_TOP:
      return 3
    default:
      return 2
  }
}

const getNumberLengthByBetType = (betType) => {
  switch (betType) {
    case LottoBetTypeEnum.SIX:
      return 6
    case LottoBetTypeEnum.FOUR:
      return 4
    case LottoBetTypeEnum.THREE_FRONT:
    case LottoBetTypeEnum.THREE_UNDER:
    case LottoBetTypeEnum.THREE_TOP:
    case LottoBetTypeEnum.THREE_TOD:
      return 3
    case LottoBetTypeEnum.TWO_TOP:
    case LottoBetTypeEnum.TWO_UNDER:
      return 2
    default:
      return 1
  }
}

const displayLottoGameRoundStatusColor = (type, resultDate) => {
  resultDate = new Date(resultDate)
  if ((type === LottoGameRoundStatusEnum.CLOSE || type === LottoGameRoundStatusEnum.OPENING) && new Date() >= resultDate) {
    return 'warning'
  }
  switch (type) {
    case LottoGameRoundStatusEnum.CLOSE:
      return 'danger'
    case LottoGameRoundStatusEnum.DONE:
    case LottoGameRoundStatusEnum.REFUND:
      return 'primary'
    default:
      return 'success'
  }
}
const displayLottoGameRoundStatusLabel = (type, resultDate) => {
  resultDate = new Date(resultDate)
  if ((type === LottoGameRoundStatusEnum.CLOSE || type === LottoGameRoundStatusEnum.OPENING) && new Date() >= resultDate) {
    return 'รอออกผล'
  }
  switch (type) {
    case LottoGameRoundStatusEnum.CLOSE:
      return 'หยุด'
    case LottoGameRoundStatusEnum.DONE:
      return 'ออกผลแล้ว'
    case LottoGameRoundStatusEnum.REFUND:
      return 'คืนโพย'
    case LottoGameRoundStatusEnum.OPENING:
      return 'รับแทง'
    default:
      return 'เปิดรอบใหม่'
  }
}

const lottoBetTypeLabel = {
  [LottoBetTypeEnum.SIX]: 'รางวัลที่ 1',
  [LottoBetTypeEnum.FOUR]: 'สี่ตัวตรง',
  [LottoBetTypeEnum.THREE_FRONT]: 'สามตัวหน้า',
  [LottoBetTypeEnum.THREE_UNDER]: 'สามตัวล่าง',
  [LottoBetTypeEnum.THREE_TOP]: 'สามตัวบน',
  [LottoBetTypeEnum.THREE_TOD]: 'สามตัวโต๊ด',
  [LottoBetTypeEnum.TWO_TOP]: 'สองตัวบน',
  [LottoBetTypeEnum.TWO_UNDER]: 'สองตัวล่าง',
  [LottoBetTypeEnum.RUN_TOP]: 'วิ่งบน',
  [LottoBetTypeEnum.RUN_UNDER]: 'วิ่งล่าง',
}

const getBetTypeLabel = (betType) => {
  return lottoBetTypeLabel[betType]
}

const displayLottoBetStatusColor = (status) => {
  switch (status) {
    case LottoBetStatusEnum.BET:
      return 'info'
    case LottoBetStatusEnum.REFUND:
      return 'danger'
    case LottoBetStatusEnum.DONE:
      return 'success'
  }
}

const displayLottoBetStatusLabel = (status) => {
  switch (status) {
    case LottoBetStatusEnum.BET:
      return 'รอออกผล'
    case LottoBetStatusEnum.REFUND:
      return 'คืนโพย'
    case LottoBetStatusEnum.DONE:
      return 'ออกผลแล้ว'
  }
}

export {
  getPrizingTypeLabel,
  getBetTypeLabel,
  displayLottoGameRoundStatusColor,
  displayLottoGameRoundStatusLabel,
  displayLottoBetStatusLabel,
  displayLottoBetStatusColor,
  getNumberLengthByPrizingType,
  getNumberLengthByBetType
}
